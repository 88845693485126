<template>
  <div>
    <div id="login-main" class="container-narrow">
      <div id="login-logo-container" class="main-logo">
        <img id="login-logo" src="/alio_rms_logo.png" alt="main-logo" />
      </div>

      <div v-if="siteLoading"></div>
      <div v-else-if="!buttonsEnabled" id="login-logging-in">
        <span>{{ $t("login.loggingIn") }}</span>
      </div>
      <b-form v-else-if="showLoginForm" @submit.prevent="login">
        <b-form-group id="login-username-container" class="input-container">
          <b-form-input
            id="login-username"
            v-model="inputFields.username"
            :state="validateState('username')"
            aria-describedby="username-live-feedback"
            type="email"
            :placeholder="$t('login.fieldPlaceholderEmail')"
            autocomplete="username"
            :maxlength="getInputMaxChar('email')"
            @blur.native="v$.inputFields.username.$touch()"
          />
          <b-form-invalid-feedback id="username-live-feedback">{{
            $t("login.usernameFeedback")
          }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="login-password-container"
          class="input-container input-container-icon"
        >
          <span>
            <SVGIconWrapper
              clickable
              class="input-icon-right"
              @click.prevent="toggleShowPassword"
            >
              <svg
                v-if="showPassword"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>
              <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                />
              </svg>
            </SVGIconWrapper>
          </span>
          <b-form-input
            id="login-password"
            v-model="v$.inputFields.password.$model"
            :type="showPassword ? 'text' : 'password'"
            :state="validateState('password')"
            :placeholder="$t('login.fieldPlaceholderPassword')"
            autocomplete="current-password"
            aria-describedby="password-live-feedback"
            :maxlength="getInputMaxChar('password')"
            @blur.native="v$.inputFields.password.$touch()"
          />
          <b-form-invalid-feedback id="password-live-feedback">
            {{ $t("login.passwordFeedback") }}
          </b-form-invalid-feedback>
        </b-form-group>
        <div id="login-actions-container" class="buttons-container">
          <button
            id="login-forgot-password-button"
            type="button"
            class="button-link"
            @click.prevent="showForgotPasswordModal"
          >
            <span>{{ $t("common.buttonForgotPassword") }}</span>
          </button>
          <button
            id="login-privacy-policy-button"
            type="button"
            class="button-link"
            @click.prevent="showPrivacyPolicyModal(false, null)"
          >
            <span>{{ $t("common.buttonPrivacyStatement") }}</span>
          </button>
        </div>
        <div id="login-button-container" class="buttons-container">
          <button
            id="login-button"
            type="submit"
            :disabled="!loginEnabled || !buttonsEnabled"
          >
            {{ $t("common.buttonLogin") }}
          </button>
        </div>
      </b-form>
      <div v-else>
        <p id="login-setup-error">{{ $t("login.errorNoSiteSpecified") }}</p>
      </div>
      <div v-if="identityProviders && identityProviders.length > 0">
        <div id="sso-continue-with">
          {{ $t("login.continueWith") }}
        </div>
        <div id="sso-options-container">
          <button
            v-for="identityProvider in identityProviders"
            :key="identityProvider"
            class="sso-login-button"
            @click="callSSOMethod(identityProvider)"
          >
            {{ identityProvider }}
          </button>
        </div>
      </div>
    </div>

    <forgot-password-modal modal-id="forgot-password-modal">
    </forgot-password-modal>

    <forgot-password-reset-modal modal-id="forgot-password-reset-modal">
    </forgot-password-reset-modal>

    <password-change-modal
      modal-id="password-change-modal"
      @completed="postLogin"
      @password-change-cancelled="logout"
    >
    </password-change-modal>

    <privacy-policy-modal
      modal-id="privacy-policy-modal"
      :require-accept="privacy.requireAccept"
      :last-accepted-version="privacy.lastAcceptedVersion"
      @last-accepted-version="updateLastAcceptedVersion"
      @policy-accepted="acceptPrivacyPolicy"
      @policy-not-accepted="declinePrivacyPolicy"
    >
    </privacy-policy-modal>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { clinicianCognitoConfig } from "@/shared/aws-exports";
import SVGIconWrapper from "@/shared/components/Primitives/SVGIconWrapper.vue";
import {
  getCurrentUser,
  signInWithRedirect,
  signIn,
  fetchUserAttributes,
  signOut,
} from "aws-amplify/auth";
import { Amplify } from "aws-amplify";

export default {
  components: { SVGIconWrapper },
  setup() {
    return {
      v$: useVuelidate({ $scope: false }),
    };
  },
  data: function () {
    return {
      siteLoading: false,
      showPassword: false,
      buttonsEnabled: true,
      privacy: {
        requireAccept: true,
        lastAcceptedVersion: null,
      },
      inputFields: {
        username: null,
        password: null,
      },
      identityProviders: null,
    };
  },
  validations: {
    inputFields: {
      username: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  computed: {
    showLoginForm() {
      return (
        !this.siteLoading &&
        (this.$store.getters.siteConfig ||
          import.meta.env.VITE_CLINICIAN_USER_POOL_ID)
      );
    },
    loginEnabled() {
      return !this.v$.$invalid;
    },
  },
  mounted: function () {
    // Check to see if the submit modal reset should be shown.
    if (this.$route.query && this.$route.query.passwordReset == 1) {
      this.$bvModal.show("forgot-password-reset-modal");
    }
  },
  created: function () {
    this.clearAllSavedAuthInfoApi();

    this.loadSiteInformation(this.$route.params.site);

    getCurrentUser()
      .then(() => {
        console.log("Signing out user.");
        this.logout();
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    callSSOMethod: function (provider) {
      signInWithRedirect({ provider: provider });
    },
    loadSiteInformation: function (site) {
      this.siteLoading = true;

      this.$store.commit("updateSiteConfig", null);

      if (site) {
        this.$store.commit("updateSite", site);
        var siteFiltered = site.replace(/[^A-Za-z0-9.-]+/g, "") + ".json";

        this.axios({
          method: "get",
          baseURL: "", // Local server
          url: "/sites/" + siteFiltered,
        })
          .then((response) => {
            this.siteLoading = false;
            this.$store.commit("updateSiteConfig", response.data);
            if (response.data?.IdentityProviders) {
              this.identityProviders = response.data?.IdentityProviders;
            }
            // Configure Amplify with the config information
            Amplify.configure(this.getAwsConfig());
          })
          .catch(() => {
            this.$router.push({ name: "login" }).then(() => {
              // necessary because we want to load this page again
              this.$router.go();
            });
          });
      } else if (import.meta.env.VITE_CLINICIAN_USER_POOL_ID) {
        this.$store.commit("updateSite", null);
        this.siteLoading = false;
        console.log("Using VITE_CLINICIAN_USER_POOL_ID");
        Amplify.configure(clinicianCognitoConfig);
      } else {
        this.siteLoading = false;
        this.$bus.emit(
          "show-general-error",
          this.$t("login.errorNoSiteSpecified")
        );
        return;
      }
    },
    async postLogin() {
      const userAttributes = await fetchUserAttributes();
      this.getVersionInformation();
      this.getClinicUserInfo(userAttributes["custom:clinicuser"]);
    },
    toggleShowPassword: function () {
      if (this.showPassword) {
        this.showPassword = false;
      } else {
        this.showPassword = true;
      }
    },
    async login() {
      // On successful login, the buttons never get re-enabled
      this.buttonsEnabled = false;

      const trimmedPassword = this.inputFields.password
        ? this.inputFields.password.trim()
        : null;
      try {
        const { isSignedIn, nextStep } = await signIn({
          username: this.inputFields.username,
          password: trimmedPassword,
        });

        // Clear the password field, this ensures the user has to re-entered
        // if they backout of a challenge or privacy policy
        this.inputFields.password = null;

        if (isSignedIn) {
          const userAttributes = await fetchUserAttributes();
          this.getVersionInformation();
          this.getClinicUserInfo(userAttributes["custom:clinicuser"]);
        } else {
          // Handle next step if there's a challenge
          switch (nextStep.signInStep) {
            case "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED":
              this.buttonsEnabled = true;
              this.showPasswordChangeModal();
              break;
            // Add other challenge types as needed
            default:
              this.$bus.emit(
                "show-general-error",
                this.$t("login.errorGeneralChallenge")
              );
              console.log("Unhandled challenge: " + nextStep.signInStep);
          }
        }
      } catch (error) {
        // Get and display error message
        this.showCognitoResponseError(error, this.$t("login.errorGeneral"));

        // Renable the buttons
        this.buttonsEnabled = true;
      }
    },
    getClinicUserInfo: function (clinicUserId) {
      if (clinicUserId === null) {
        this.$bus.emit(
          "show-general-error",
          this.$t("login.errorUserInvalidConfiguration")
        );
      } else {
        getCurrentUser()
          .then(async () => {
            const response = await this.api.v1WhoamiGet();
            const clinicUserId = response.data.clinicuserid;
            const groups = response.data.groups;

            // Save the current clinic user information
            this.setCurrentUserId(clinicUserId);

            this.saveLastLoginTime();

            // Refresh the clinic user information
            this.refreshCurrentUserInfoFromApi(
              (response) => {
                const clinicUser = response.data.clinicuser;

                if (!clinicUser) {
                  // Renable the buttons
                  this.buttonsEnabled = true;

                  this.$bus.emit(
                    "show-general-error",
                    this.$t("login.errorNoUser")
                  );
                } else {
                  // Add the groups to the user
                  clinicUser.groups = groups;
                  this.$store.commit("updateUser", clinicUser);

                  this.showPrivacyPolicyModal(
                    true,
                    clinicUser.privacypolicyacceptedversion
                  );
                }
              },
              (error) => {
                // Renable the buttons
                this.buttonsEnabled = true;

                // Get and show the error message
                this.showApiResponseError(
                  error,
                  this.$t("login.errorLoadDetails")
                );
              }
            );
          })
          .catch((error) => {
            this.$bus.emit("show-general-error", error.message);
          });
      }
    },
    saveLastLoginTime: function () {
      // Accept Privacy Policy not currently support, so just shortcut it
      this.api
        .v1ClinicuserSelfLoginPost()
        .then(() => {
          // Do nothing on success
        })
        .catch((error) => {
          // Get and log any error message
          this.logApiResponseError(
            "Login Time",
            error,
            this.$t("login.errorLoginTracking")
          );
        });
    },
    showPasswordChangeModal: function () {
      this.$bvModal.show("password-change-modal");
    },
    showPrivacyPolicyModal: function (requireAccept, lastAcceptedVersion) {
      this.privacy.requireAccept = requireAccept;
      if (
        lastAcceptedVersion === null ||
        this.privacy.lastAcceptedVersion !== lastAcceptedVersion
      ) {
        this.$bvModal.show("privacy-policy-modal");
      } else {
        this.completeLogin();
      }
    },
    acceptPrivacyPolicy: function (version) {
      // Accept Privacy Policy not currently support, so just shortcut it
      this.api
        .v1ClinicuserSelfAcceptprivacypolicyPut({
          acceptance: {
            privacypolicyacceptedversion: version,
          },
        })
        .then(() => {
          this.completeLogin();
        })
        .catch((error) => {
          // Renable the buttons
          this.buttonsEnabled = true;

          // Get and display the error message
          this.showApiResponseError(
            error,
            this.$t("privacyPolicy.errorAccept")
          );
        });
    },
    declinePrivacyPolicy: function () {
      // Renable the buttons
      this.buttonsEnabled = true;
    },
    updateLastAcceptedVersion: function (version) {
      this.privacy.lastAcceptedVersion = version;
    },
    completeLogin: function () {
      var clinicUser = this.$store.getters.user;

      if (
        !clinicUser ||
        clinicUser.clinics.length === 0 ||
        clinicUser.clinics[0] === null
      ) {
        // Renable the buttons
        this.buttonsEnabled = true;

        this.$bus.emit(
          "show-general-error",
          this.$t("login.errorNotAssociated")
        );
      } else {
        let clinicId = clinicUser.clinics[0];
        let next = this.$route.query.next;

        // Save the current clinic ID
        this.setCurrentClinicId(clinicId);

        if (next) {
          this.$router.replace({ path: next });
        } else {
          this.$router.replace({ name: "find-home" });
        }

        // Send an event for successful login
        this.$bus.emit("login-complete");
      }
    },
    logout: function () {
      signOut({
        global: true,
      })
        .then(() => {
          // pass
        })
        .catch((error) => {
          this.showCognitoResponseError(error, this.$t("login.errorLogout"));
        });
    },
    showForgotPasswordModal: function () {
      this.$bvModal.show("forgot-password-modal");
    },
  },
};
</script>
<style scoped lang="scss">
button {
  margin: 0;
}
#login-logo {
  width: 200px;
}

#login-logging-in {
  margin-top: 40px;

  span {
    font-size: 18px;
  }
}

#login-username-container {
  margin-top: 40px;
}

#sso-options-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 5px;
}

#sso-continue-with {
  color: $gray-mid;
  margin: 20px 0px 20px 0px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;

  text-transform: uppercase;
}

.sso-login-button {
  color: $gray-mid;
  background-color: transparent;
  border: 2px solid $gray-light;
  width: 48% !important;
}

#login-actions-container {
  margin-top: 24px;
  justify-content: space-between;
}

#login-setup-error {
  margin-top: 50px;
  font-size: 18px;
  color: $red;
}
</style>
