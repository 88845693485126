<template>
  <div>
    <sub-header
      :last-refresh-label="$t('settingsClinician.lastRefreshLabel')"
      :last-refresh-date="lastRefreshDate"
      :action-buttons="actionButtons"
      :show-last-viewed="false"
      @refresh="refreshAllInformation"
      @dashboard-button-event="navViewPatientList"
    >
    </sub-header>

    <div class="main-section container-fluid">
      <b-tabs class="settings" justified>
        <b-tab
          :title="$t('settingsClinician.tabNotifications')"
          active
          class="settings-tab"
        >
          <div
            v-if="
              currentClinicLoadResult === null ||
              currentClinicUserLoadResult === null
            "
            id="settings-loading"
            class="data-loading-container"
          >
            <span>{{ $t("common.loading") }}</span>
          </div>
          <div v-else>
            <form class="narrow-tab" @submit.prevent="saveSettings">
              <section-header header-text="Personal Settings" class="mb-2" />
              <sub-section-header
                :header-text="$t('settingsClinician.headingNotifications')"
              />
              <div class="setting-section">
                <div
                  id="email-notification-enable-container"
                  class="input-container-settings-select"
                >
                  <label for="email-notifications-select">{{
                    $t("settingsClinician.labelEmailNotificationsEnable")
                  }}</label>
                  <b-form-select
                    id="email-notifications-enable-select"
                    v-model="inputFields.notification.enable"
                    :options="selects.notify"
                  >
                  </b-form-select>
                </div>
                <div
                  id="email-notification-email-container"
                  class="input-container-settings-select"
                >
                  <label for="email-notifications-email">
                    {{
                      $t("settingsClinician.labelEmailNotificationsEmail", {
                        name: formatClinicUserName(currentUser),
                      })
                    }}
                  </label>
                  <input
                    id="email-notifications-email"
                    v-model="inputFields.notification.email"
                    type="text"
                    :placeholder="
                      $t('settingsClinician.placeholderNotificationsEmail')
                    "
                    :maxlength="getInputMaxChar('email')"
                  />
                </div>
              </div>
              <section-header header-text="Clinic Settings" class="mt-4 mb-2" />
              <sub-section-header
                :header-text="$t('settingsClinician.headingSkinTemperature')"
              />
              <div class="setting-section">
                <div
                  id="skin-temperature-units-container"
                  class="input-container-settings-select"
                >
                  <label for="skin-temperature-units-select">{{
                    $t("settingsClinician.labelSkinTemperatureUnit")
                  }}</label>
                  <b-form-select
                    id="skin-temperature-units-select"
                    v-model="inputFields.settings.tempDisplayUnits"
                    :options="selects.temperature.units"
                    :disabled="isNotClinicAdmin"
                  >
                  </b-form-select>
                </div>
              </div>
              <potassium-settings
                v-if="isPotassiumSupported"
                class="mt-4"
                :notify-low-potassium.sync="
                  inputFields.potassiumSettings.notifyLowPotassium
                "
                :notify-high-potassium.sync="
                  inputFields.potassiumSettings.notifyHighPotassium
                "
                :notify-dyskalemia.sync="
                  inputFields.potassiumSettings.notifyDyskalemia
                "
                :number-dyskalemia.sync="
                  inputFields.potassiumSettings.numberDyskalemia
                "
                :number-low-potassium.sync="
                  inputFields.potassiumSettings.numberLowPotassium
                "
                :number-high-potassium.sync="
                  inputFields.potassiumSettings.numberHighPotassium
                "
                :ordinal="clinic.ordinal_potassium"
                :disabled="isNotClinicAdmin"
                :clinic-id-parameter="clinicIdParameter"
              />
              <hct-or-hgb-settings
                v-if="isHCTSupported"
                class="mt-4"
                type="hct"
                :notify.sync="inputFields.hctSettings.notify"
                :threshold-low.sync="inputFields.hctSettings.thresholdLow"
                :threshold-high.sync="inputFields.hctSettings.thresholdHigh"
                :number-low.sync="inputFields.hctSettings.numberLow"
                :number-high.sync="inputFields.hctSettings.numberHigh"
                :disabled="isNotClinicAdmin"
                :clinic-id-parameter="clinicIdParameter"
              />
              <hct-or-hgb-settings
                v-if="isHGBSupported"
                class="mt-4"
                type="hgb"
                :notify.sync="inputFields.hgbSettings.notify"
                :threshold-low.sync="inputFields.hgbSettings.thresholdLow"
                :threshold-high.sync="inputFields.hgbSettings.thresholdHigh"
                :number-low.sync="inputFields.hgbSettings.numberLow"
                :number-high.sync="inputFields.hgbSettings.numberHigh"
                :disabled="isNotClinicAdmin"
                :clinic-id-parameter="clinicIdParameter"
              />
              <div id="settings-button-container" class="buttons-container">
                <button
                  id="settings-save"
                  type="submit"
                  :disabled="!isSettingSaveEnabled"
                >
                  {{ $t("common.buttonSave") }}
                </button>
                <button
                  id="settings-reset"
                  type="button"
                  class="button-negative"
                  @click.prevent="resetSettings"
                >
                  {{ $t("common.buttonReset") }}
                </button>
              </div>
            </form>
          </div>
        </b-tab>

        <b-tab
          :title="$t('settingsClinician.tabPatchPlacement')"
          class="settings-tab"
        >
          <div class="settings-column-multiple">
            <div class="placement-message-container">
              <p>{{ $t("patchLocation.clinicPlacementListMessage") }}</p>
            </div>
            <div>
              <section-header
                :header-text="
                  $t('settingsClinician.headingAllPlacementLocations')
                "
              />
              <div class="setting-section-list">
                <div
                  v-if="currentClinicLoadResult === null"
                  id="patch-locations-loading"
                  class="data-loading-container"
                >
                  <span>{{ $t("patchLocation.loading") }}</span>
                </div>
                <div v-else-if="clinic && clinic.patchlocations">
                  <div
                    v-for="(location, index) in activeLocations"
                    :key="index"
                    class="settings-list"
                  >
                    <span>{{ formatPatchLocationInfomation(location) }}</span>
                  </div>
                </div>
                <div v-else>
                  <span>{{ $t("patchLocation.errorNoneFound") }}</span>
                </div>
              </div>
            </div>
          </div>
        </b-tab>

        <b-tab
          :title="$t('settingsClinician.tabClinicalUsers')"
          class="settings-tab"
        >
          <div class="settings-column-multiple">
            <div class="add-new-section">
              <section-header
                :header-text="$t('settingsClinician.headingAddNewUser')"
              />
              <div class="setting-section">
                <form @submit.prevent="addClinicUser">
                  <div
                    id="clinic-user-first-name-container"
                    class="input-container-settings"
                  >
                    <input
                      id="user-first-name"
                      v-model="inputFields.newClinicUser.firstName"
                      type="text"
                      :placeholder="
                        $t(
                          'settingsClinician.placeholderClinicalUserNewFirstName'
                        )
                      "
                      :disabled="isNotClinicAdmin"
                      :maxlength="getInputMaxChar('first-name')"
                    />
                  </div>
                  <div
                    id="clinic-user-last-name-container"
                    class="input-container-settings"
                  >
                    <input
                      id="user-last-name"
                      v-model="inputFields.newClinicUser.lastName"
                      type="text"
                      :placeholder="
                        $t(
                          'settingsClinician.placeholderClinicalUserNewLastName'
                        )
                      "
                      :disabled="isNotClinicAdmin"
                      :maxlength="getInputMaxChar('last-name')"
                    />
                  </div>
                  <div
                    id="clinic-user-email-container"
                    class="input-container-settings"
                  >
                    <input
                      id="user-email"
                      v-model="inputFields.newClinicUser.email"
                      type="text"
                      :placeholder="
                        $t('settingsClinician.placeholderClinicalUserNewEmail')
                      "
                      :disabled="isNotClinicAdmin"
                      :maxlength="getInputMaxChar('email')"
                    />
                  </div>
                  <div
                    id="clinic-user-admin-container"
                    class="input-container-settings"
                  >
                    <b-form-select
                      id="user-administrator-privileges"
                      v-model="
                        inputFields.newClinicUser.administratorPrivileges
                      "
                      :options="selects.administratorPrivileges"
                      :disabled="isNotClinicAdmin"
                    />
                  </div>
                  <div
                    id="clinic-user-add-button-container"
                    class="buttons-container"
                  >
                    <button
                      id="add-new-user-button"
                      type="submit"
                      :disabled="!isAddClinicUserEnabled()"
                    >
                      {{ $t("common.buttonAdd") }}
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div>
              <section-header
                :header-text="$t('settingsClinician.headingAllClinicalUsers')"
              />
              <div class="setting-section-list">
                <div
                  v-if="clinicUsersLoadResult === null"
                  id="clinic-users-loading"
                  class="data-loading-container"
                >
                  <span>{{ $t("clinicalUser.loading") }}</span>
                </div>
                <div v-else-if="clinicUsers">
                  <div
                    v-for="(clinicUser, index) in clinicUsers"
                    :key="index"
                    class="settings-list"
                  >
                    <div
                      class="clinician-name-label"
                      :class="[clinicUser.active ? '' : 'clinic-user-inactive']"
                    >
                      <span>{{ formatClinicUserName(clinicUser) }}</span>
                      <span class="clinic-user-adminstrator-label">{{
                        isClinicUserAdmin(clinicUser, clinicIdParameter)
                          ? "Administrator"
                          : ""
                      }}</span>
                    </div>
                    <div>
                      <span v-if="clinicUser.lastlogin">
                        last login {{ formatTimestamp(clinicUser.lastlogin) }}
                      </span>
                      <span v-else
                        >invite sent
                        {{ formatTimestamp(clinicUser.create_ts) }}</span
                      >
                    </div>
                    <div class="user-actions-container">
                      <button
                        type="button"
                        class="button-link"
                        :disabled="isNotClinicAdmin"
                        @click.prevent="openEditModal(clinicUser)"
                      >
                        {{ $t("common.buttonEdit") }}
                      </button>
                      <button
                        v-if="clinicUser.active"
                        type="button"
                        class="button-link remove-link"
                        :disabled="
                          !canDeactivate(
                            currentUser,
                            clinicIdParameter,
                            clinicUser
                          )
                        "
                        @click.prevent="deactivateClinicUser(clinicUser)"
                      >
                        {{ $t("common.buttonDeactivate") }}
                      </button>
                      <button
                        v-else
                        type="button"
                        class="button-link"
                        :disabled="isNotClinicAdmin"
                        @click.prevent="activateClinicUser(clinicUser)"
                      >
                        {{ $t("common.buttonActivate") }}
                      </button>
                      <button
                        type="button"
                        class="button-link"
                        :disabled="
                          !canResend(currentUser, clinicIdParameter, clinicUser)
                        "
                        @click.prevent="resetPasswordClinicUser(clinicUser)"
                      >
                        {{
                          clinicUser?.lastlogin
                            ? $t("common.buttonResetPassword")
                            : $t("common.buttonResendInvite")
                        }}
                      </button>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <span>{{ $t("clinicalUser.errorNoneFound") }}</span>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab :title="'User Groups'" class="settings-tab">
          <div class="settings-column-multiple">
            <div class="add-new-section">
              <add-user-group-modal
                :clinic-id="clinicIdParameter"
                @added="updateUserGroups"
              />
            </div>
            <div class="narrow-tab">
              <user-group-list
                :key="userGroupListKey"
                :clinic-users="clinicUsers"
              />
            </div>
          </div>
        </b-tab>
        <b-tab :title="$t('settingsClinician.tabAbout')" class="settings-tab">
          <div class="narrow-tab">
            <section-header
              :header-text="
                $t('settingsClinician.headingApplicationInformation')
              "
            />
            <div class="setting-section">
              <info-item
                :item="{
                  label: $t('settingsClinician.labelVersionNumber'),
                  value: formattedVersionNumber,
                }"
              />
              <info-item
                :item="{
                  label: $t('settingsClinician.labelUniqueDeviceIdentifier'),
                  value: formattedDeviceIdentifier,
                }"
              />
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <add-edit-clinic-user-modal
      modal-id="add-edit-clinic-user-modal"
      :clinic-id="clinicIdParameter"
      :clinic-user-to-edit="clinicUserToEdit"
      @updated="getClinicUsers"
    >
    </add-edit-clinic-user-modal>
  </div>
</template>
<script>
import SubSectionHeader from "@/shared/components/Primitives/SubSectionHeader.vue";
import SectionHeader from "@/shared/components/Primitives/SectionHeader.vue";
import InfoItem from "@/shared/components/Primitives/InfoItem.vue";
import AddEditClinicUserModal from "@/shared/components/AddEditClinicUserModal.vue";

export default {
  components: {
    SubSectionHeader,
    SectionHeader,
    InfoItem,
    AddEditClinicUserModal,
  },
  data: function () {
    return {
      lastRefreshDate: null,
      actionButtons: [
        {
          label: this.$t("navigation.dashboard"),
          id: "dashboard-sub-header-button",
          event: "dashboard-button-event",
        },
      ],
      currentClinicLoadResult: null,
      clinic: null,
      currentClinicUserLoadResult: false,
      clinicUsersLoadResult: false,
      clinicUsers: [],
      versionTrackerResult: false,
      versionTracker: null,
      versionTrackerUrl: import.meta.env.VITE_VERSION_TRACKER_URL,
      updateClinicUserSettingsResult: null,
      updateClinicSettingsResult: null,
      userGroupListKey: 0,
      clinicUserToEdit: null,
      selects: {
        notify: [
          { value: "false", text: this.$t("common.no") },
          { value: "true", text: this.$t("common.yes") },
        ],
        temperature: {
          units: [
            { value: "fahrenheit", text: "F" },
            { value: "celsius", text: "C" },
          ],
        },
        administratorPrivileges: [
          {
            value: "false",
            text: this.$t(
              "clinicalUser.selectAdministratorPrivilegesNotSupported"
            ),
          },
          {
            value: "true",
            text: this.$t(
              "clinicalUser.selectAdministratorPrivilegesSupported"
            ),
          },
        ],
      },
      inputFields: {
        notification: {
          enable: "true",
          email: null,
        },
        settings: {
          tempDisplayUnits: "fahrenheit",
        },
        potassiumSettings: {
          notifyLowPotassium: true,
          numberLowPotassium: 4,
          notifyHighPotassium: true,
          numberHighPotassium: 4,
          notifyDyskalemia: true,
          numberDyskalemia: 4,
        },
        hgbSettings: {
          notify: true,
          thresholdLow: null,
          thresholdHigh: null,
          numberLow: null,
          numberHigh: null,
        },
        hctSettings: {
          notify: true,
          thresholdLow: null,
          thresholdHigh: null,
          numberLow: null,
          numberHigh: null,
        },
        patch: {
          name: null,
          threshold: null,
        },
        newClinicUser: {
          firstName: null,
          lastName: null,
          email: null,
          administratorPrivileges: "false",
        },
      },
    };
  },
  computed: {
    activeLocations() {
      if (!this.clinic) return [];
      if (!this.clinic.patchlocations) return [];
      return this.clinic.patchlocations.filter((location) => location.active);
    },
    isNotClinicAdmin() {
      return !this.isClinicUserAdmin(this.currentUser, this.clinicIdParameter);
    },
    isPotassiumSupported() {
      return this.hasPotassiumFeatureSupport(this.clinic);
    },
    isHCTSupported() {
      return this.hasHctFeatureSupport(this.clinic);
    },
    isHGBSupported() {
      return this.hasHgbFeatureSupport(this.clinic);
    },
    hasClinicSettingsChanged: function () {
      if (this.currentClinicLoadResult === true) {
        if (
          this.inputFields.settings.tempDisplayUnits !==
          this.clinic.tempdisplayunits
        ) {
          return true;
        }
        if (
          this.inputFields.potassiumSettings.notifyDyskalemia !==
          this.clinic.notify_dyskalemia
        ) {
          return true;
        }
        if (
          this.inputFields.potassiumSettings.numberDyskalemia !==
          this.clinic.number_dyskalemia
        ) {
          return true;
        }
        if (
          this.inputFields.potassiumSettings.notifyLowPotassium !==
          this.clinic.notify_low_potassium
        ) {
          return true;
        }
        if (
          this.inputFields.potassiumSettings.numberLowPotassium !==
          this.clinic.number_low_potassium
        ) {
          return true;
        }
        if (
          this.inputFields.potassiumSettings.notifyHighPotassium !==
          this.clinic.notify_high_potassium
        ) {
          return true;
        }
        if (
          this.inputFields.potassiumSettings.numberHighPotassium !==
          this.clinic.number_high_potassium
        ) {
          return true;
        }
        if (this.inputFields.hctSettings.notify !== this.clinic.notify_hct) {
          return true;
        }
        if (
          this.inputFields.hctSettings.thresholdLow !==
          this.clinic.threshold_low_hct
        ) {
          return true;
        }
        if (
          this.inputFields.hctSettings.thresholdHigh !==
          this.clinic.threshold_high_hct
        ) {
          return true;
        }
        if (this.inputFields.hgbSettings.notify !== this.clinic.notify_hgb) {
          return true;
        }
        if (
          this.inputFields.hgbSettings.thresholdLow !==
          this.clinic.threshold_low_hgb
        ) {
          return true;
        }
        if (
          this.inputFields.hgbSettings.thresholdHigh !==
          this.clinic.threshold_high_hgb
        ) {
          return true;
        }
        if (
          this.inputFields.hgbSettings.numberLow !== this.clinic.number_low_hgb
        ) {
          return true;
        }
        if (
          this.inputFields.hgbSettings.numberHigh !==
          this.clinic.number_high_hgb
        ) {
          return true;
        }
        if (
          this.inputFields.hctSettings.numberLow !== this.clinic.number_low_hct
        ) {
          return true;
        }
        if (
          this.inputFields.hctSettings.numberHigh !==
          this.clinic.number_high_hct
        ) {
          return true;
        }
      }

      return false;
    },
    hasClinicUserSettingsChanged: function () {
      if (this.currentClinicUserLoadResult === true) {
        if (
          this.inputFields.notification.enable !==
            this.toStringFromBoolean(this.currentUser.sendemail) ||
          this.inputFields.notification.email !==
            this.currentUser.notificationemail
        ) {
          if (this.validateInputEmail(this.inputFields.notification.email)) {
            return true;
          }
        }
      }

      return false;
    },
    isSettingSaveEnabled: function () {
      if (this.hasClinicUserSettingsChanged || this.hasClinicSettingsChanged) {
        return true;
      }

      return false;
    },
    clinicIdParameter: function () {
      return parseInt(this.$route.params.clinicId, 10);
    },
    currentUser: function () {
      return this.$store.getters.user;
    },
    formattedVersionNumber: function () {
      const release = this.$store.getters.release;
      if (release) {
        return release;
      }
      return "-";
    },
    formattedDeviceIdentifier: function () {
      return this.$store.getters.udi;
    },
  },
  mounted() {
    // Make sure the clinic ID is valid
    if (this.verifyClinicId(this.clinicIdParameter)) {
      // Refresh all information relevant to the settings to ensure it is at the latest
      this.refreshAllInformation();
    }
  },
  methods: {
    formatTimestamp(timestamp) {
      let formattedDate = this.dateOrEpochFormat(timestamp, true);
      if (formattedDate) {
        return formattedDate;
      }
    },
    openEditModal(clinicUser) {
      this.clinicUserToEdit = clinicUser;
      this.$bvModal.show("add-edit-clinic-user-modal");
    },
    canDeactivate: function (currentUser, clinicIdParameter, clinicUser) {
      if (currentUser.id === clinicUser.id) return false;
      return this.isClinicUserAdmin(currentUser, clinicIdParameter);
    },
    canResend: function (currentUser, clinicIdParameter, clinicUser) {
      if (currentUser.id === clinicUser.id) return false;
      return this.isClinicUserAdmin(currentUser, clinicIdParameter);
    },
    refreshAllInformation() {
      // Update the last refresh date to when the commands were sent
      this.lastRefreshDate = null;

      // Get all updated information
      this.refreshCurrentClinicUser();
      this.refreshCurrentClinic();
      this.getClinicUsers();
      this.getVersionInformation();
    },
    updateUserGroups: function () {
      this.userGroupListKey += 1;
    },
    refreshCurrentClinicUser() {
      // Get the latest Clinic information
      this.currentClinicUserLoadResult = null;

      this.refreshCurrentUserInfoFromApi(
        () => {
          this.currentClinicUserLoadResult = true;
          this.resetSettings();

          // Update the last refresh if all commands are successful
          this.updateLastRefresh();
        },
        (error) => {
          this.currentClinicUserLoadResult = false;

          // Get the error and show it
          this.showApiResponseError(
            error,
            this.$t("clinicalUser.errorLoadDetails")
          );
        }
      );
    },
    refreshCurrentClinic() {
      // Get the latest Clinic information
      this.currentClinicLoadResult = null;
      this.clinic = null;

      this.getClinicInfo(
        this.clinicIdParameter,
        true,
        (clinic) => {
          this.clinic = clinic;

          this.currentClinicLoadResult = true;
          this.resetSettings();

          // Update the last refresh if all commands are successful
          this.updateLastRefresh();
        },
        (error) => {
          this.currentClinicLoadResult = false;

          // Get the error and show it
          this.showApiResponseError(error, this.$t("clinic.errorLoadDetails"));
        }
      );
    },
    getClinicUsers: function () {
      this.clinicUserToEdit = null;
      this.clinicUsersLoadResult = null;
      this.clinicUsers = [];

      this.api
        .v1ClinicClinicidUsersGet(this.clinicIdParameter)
        .then((response) => {
          this.clinicUsersLoadResult = true;
          this.clinicUsers = response.data.clinicusers;

          // Update the last refresh if all commands are successful
          this.updateLastRefresh();
        })
        .catch((error) => {
          this.clinicUsersLoadResult = false;

          // Get the error and show it
          this.showApiResponseError(
            error,
            this.$t("clinicalUser.errorLoadList")
          );
        });
    },
    updateLastRefresh: function () {
      if (
        this.currentClinicLoadResult === true &&
        this.currentClinicUserLoadResult === true &&
        this.clinicUsersLoadResult === true &&
        this.versionTrackerResult === true
      ) {
        // Update the last refresh date if all commands were successful
        this.lastRefreshDate = new Date();
      }
    },
    resetSettings: function () {
      if (
        this.currentClinicUserLoadResult === true &&
        this.currentClinicLoadResult === true
      ) {
        // Default these to true once a reset is sent
        this.updateClinicUserSettingsResult = true;
        this.updateClinicSettingsResult = true;

        // Allow the settings to be reset if all the commands have been updated
        this.inputFields.notification.enable = this.toStringFromBoolean(
          this.currentUser.sendemail
        );
        this.inputFields.notification.email = this.currentUser.notificationemail
          ? this.currentUser.notificationemail
          : this.currentUser.cognitoemail;

        this.inputFields.settings.tempDisplayUnits =
          this.clinic.tempdisplayunits;
        this.inputFields.potassiumSettings.notifyLowPotassium =
          this.clinic.notify_low_potassium;
        this.inputFields.potassiumSettings.numberLowPotassium =
          this.clinic.number_low_potassium;
        this.inputFields.potassiumSettings.notifyHighPotassium =
          this.clinic.notify_high_potassium;
        this.inputFields.potassiumSettings.numberHighPotassium =
          this.clinic.number_high_potassium;
        this.inputFields.potassiumSettings.notifyDyskalemia =
          this.clinic.notify_dyskalemia;
        this.inputFields.potassiumSettings.numberDyskalemia =
          this.clinic.number_dyskalemia;
        this.inputFields.hctSettings.notify = this.clinic.notify_hct;
        this.inputFields.hctSettings.thresholdLow =
          this.clinic.threshold_low_hct;
        this.inputFields.hctSettings.thresholdHigh =
          this.clinic.threshold_high_hct;
        this.inputFields.hgbSettings.notify = this.clinic.notify_hgb;
        this.inputFields.hgbSettings.thresholdLow =
          this.clinic.threshold_low_hgb;
        this.inputFields.hgbSettings.thresholdHigh =
          this.clinic.threshold_high_hgb;
        this.inputFields.hgbSettings.numberLow = this.clinic.number_low_hgb;
        this.inputFields.hgbSettings.numberHigh = this.clinic.number_high_hgb;
        this.inputFields.hctSettings.numberLow = this.clinic.number_low_hct;
        this.inputFields.hctSettings.numberHigh = this.clinic.number_high_hct;
      }
    },
    saveSettings: function () {
      if (this.hasClinicUserSettingsChanged) {
        this.updateClinicianSettings();
      }

      if (this.hasClinicSettingsChanged) {
        this.updateClinicSettings();
      }
    },
    updateClinicianSettings() {
      this.updateClinicUserSettingsResult = null;

      this.api
        .v1ClinicuserClinicuseridPut(this.currentUser.id, {
          updateclinicuser: {
            sendemail: this.toBooleanFromString(
              this.inputFields.notification.enable
            ),
            notificationemail: this.inputFields.notification.email,
          },
        })
        .then(() => {
          this.updateClinicUserSettingsResult = true;

          // Show a settings success message
          if (this.showSettingsSuccessMessage()) {
            // Refresh all information if both successful
            this.refreshAllInformation();
          }
        })
        .catch((error) => {
          this.updateClinicUserSettingsResult = false;

          // Get the error and display it
          this.showApiResponseError(
            error,
            this.$t("clinicalUser.errorUpdateSettings")
          );
        });
    },
    updateClinicSettings() {
      this.updateClinicSettingsResult = null;

      this.api
        .v1ClinicClinicidPut(this.getCurrentClinicId(), {
          updateclinic: {
            tempdisplayunits: this.inputFields.settings.tempDisplayUnits,
            notify_low_potassium:
              this.inputFields.potassiumSettings.notifyLowPotassium,
            notify_high_potassium:
              this.inputFields.potassiumSettings.notifyHighPotassium,
            number_low_potassium:
              this.inputFields.potassiumSettings.numberLowPotassium,
            number_high_potassium:
              this.inputFields.potassiumSettings.numberHighPotassium,
            notify_dyskalemia:
              this.inputFields.potassiumSettings.notifyDyskalemia,
            number_dyskalemia:
              this.inputFields.potassiumSettings.numberDyskalemia,
            notify_hct: this.inputFields.hctSettings.notify,
            notify_hgb: this.inputFields.hgbSettings.notify,
            threshold_low_hct: this.inputFields.hctSettings.thresholdLow,
            threshold_low_hgb: this.inputFields.hgbSettings.thresholdLow,
            threshold_high_hct: this.inputFields.hctSettings.thresholdHigh,
            threshold_high_hgb: this.inputFields.hgbSettings.thresholdHigh,
            number_low_hct: this.inputFields.hctSettings.numberLow,
            number_low_hgb: this.inputFields.hgbSettings.numberLow,
            number_high_hct: this.inputFields.hctSettings.numberHigh,
            number_high_hgb: this.inputFields.hgbSettings.numberHigh,
          },
        })
        .then(() => {
          this.updateClinicSettingsResult = true;

          // Show a settings success message
          if (this.showSettingsSuccessMessage()) {
            // Refresh all information if both successful
            this.refreshAllInformation();
          }
        })
        .catch((error) => {
          this.updateClinicSettingsResult = false;

          // Get the error and display it
          this.showApiResponseError(
            error,
            this.$t("clinic.errorUpdateSettings")
          );
        });
    },
    showSettingsSuccessMessage: function () {
      if (
        this.updateClinicUserSettingsResult === true &&
        this.updateClinicSettingsResult === true
      ) {
        // Show a general success message if both results are true. Note that both commands
        // may not always be sent
        this.$bus.emit(
          "show-general-success",
          this.$t("clinic.successUpdateSettings")
        );
        return true;
      }

      return false;
    },
    resetPatchPlacementInputs: function () {
      this.inputFields.patch.name = null;
    },
    isAddPatchPlacementEnabled: function () {
      // There currently is no minumum length specified, so if there
      // are any characters the button should be enabled.
      if (this.validateInputMinChar(this.inputFields.patch.name, 1)) {
        return true;
      }

      return false;
    },
    formatPatchLocationInfomation: function (location) {
      return location.locationname;
    },
    isAddClinicUserEnabled: function () {
      if (
        this.validateInputMinChar(
          this.inputFields.newClinicUser.firstName,
          1
        ) &&
        this.validateInputMinChar(this.inputFields.newClinicUser.lastName, 2) &&
        this.validateInputEmail(this.inputFields.newClinicUser.email)
      ) {
        return true;
      }

      return false;
    },
    addClinicUser: function () {
      if (this.isAddClinicUserEnabled()) {
        if (this.inputFields.newClinicUser.administratorPrivileges === "true") {
          // Create Clinic Admin
          this.api
            .v1ClinicadminPost({
              createclinicadmin: {
                clinics: [this.clinic.id],
                firstname: this.inputFields.newClinicUser.firstName,
                lastname: this.inputFields.newClinicUser.lastName,
                cognitoemail: this.inputFields.newClinicUser.email,
              },
            })
            .then(() => {
              // Show a general success message
              this.$bus.emit(
                "show-general-success",
                this.$t("clinicalUser.successAddAdmin")
              );

              // Clear all the clinic user inputs on success.
              this.clearClinicUserInputs();

              // Refresh all information
              this.refreshAllInformation();
            })
            .catch((error) => {
              // Get the error and display it
              this.showApiResponseError(
                error,
                this.$t("clinicalUser.errorAddAdmin")
              );
            });
        } else {
          // Create Clinic user
          this.api
            .v1ClinicuserPost({
              createclinicuser: {
                clinics: [this.clinic.id],
                firstname: this.inputFields.newClinicUser.firstName,
                lastname: this.inputFields.newClinicUser.lastName,
                cognitoemail: this.inputFields.newClinicUser.email,
              },
            })
            .then(() => {
              // Show a general success message
              this.$bus.emit(
                "show-general-success",
                this.$t("clinicalUser.successAddUser")
              );

              // Clear all the clinic user inputs on success.
              this.clearClinicUserInputs();

              // Refresh all information
              this.refreshAllInformation();
            })
            .catch((error) => {
              // Get the error and display it
              this.showApiResponseError(
                error,
                this.$t("clinicalUser.errorAddUser")
              );
            });
        }
      }
    },
    clearClinicUserInputs: function () {
      this.inputFields.newClinicUser.firstName = null;
      this.inputFields.newClinicUser.lastName = null;
      this.inputFields.newClinicUser.email = null;
      this.inputFields.newClinicUser.administratorPrivileges = "false";
    },
    deactivateClinicUser: function (clinicUser) {
      let clinicUserId = clinicUser.id;
      var eventId = "clinic-user-" + clinicUserId;
      this.showConfirmationPrompt(
        this.$t("clinicalUser.promptDeactivate"),
        eventId,
        (state) => {
          if (state) {
            // Make the clinic user inactive
            this.updateClinicUserState(clinicUser, false);
          }
        }
      );
    },
    activateClinicUser: function (clinicUser) {
      this.updateClinicUserState(clinicUser, true);
    },
    updateClinicUserState(clinicUser, newActiveState) {
      this.api
        .v1ClinicuserClinicuseridPut(clinicUser.id, {
          updateclinicuser: {
            active: newActiveState,
          },
        })
        .then(() => {
          // Show a general success message
          this.$bus.emit(
            "show-general-success",
            this.$t("clinicalUser.successUpdate")
          );

          // Refresh all information
          this.refreshAllInformation();
        })
        .catch((error) => {
          // Get the error and display it
          this.showApiResponseError(error, this.$t("clinicalUser.errorUpdate"));
        });
    },
    resetPasswordClinicUser: function (clinicUser) {
      let clinicUserId = clinicUser.id;

      this.api
        .v1ClinicuserClinicuseridResetpasswordPost(clinicUserId)
        .then(() => {
          // Show a general success message
          this.$bus.emit(
            "show-general-success",
            this.$t("clinicalUser.successInviteResent")
          );
        })
        .catch((error) => {
          // Get the error and display it
          this.showApiResponseError(
            error,
            this.$t("clinicalUser.errorResendingInvite")
          );
        });
    },
    navViewPatientList: function () {
      this.$router.push({
        name: "patient-list",
        params: { clinicId: this.clinicIdParameter },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.user-actions-container > button {
  margin-right: 16px;
}
.placement-message-container {
  max-width: 300px;
}
.setting-section {
  > * {
    margin-top: 16px;
  }
}

.settings-column-multiple {
  display: flex;

  @media (max-width: 959px) {
    flex-direction: column;
  }

  margin-bottom: 20px;

  > div {
    margin-right: 40px;
  }
}

#email-notifications-email {
  flex-grow: 1;

  @media (max-width: 959px) {
    width: 100%;
  }
}

.input-container-settings {
  input,
  select {
    width: 280px;
  }
}

.settings-list {
  display: grid;
  grid-template-columns: repeat(
    3,
    1fr
  ); // Adjust the number of columns as needed
  gap: 16px; // Adjust the gap between cells
  align-items: center;
  width: 100%;
  padding-bottom: 16px;

  @media (max-width: 500px) {
    grid-template-columns: 1fr; // Stack items in a single column on small screens
  }
}

.settings-list-wrapper {
  // If .settings-list-wrapper is used for additional layout control, adjust as needed
  display: contents; // Consider using 'contents' if it's only a wrapper without styling
}

.clinician-name-label {
  // Adjustments for specific elements if needed
}

span {
  display: block;
}

.clinic-user-adminstrator-label {
  min-height: 14px;

  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
}

.settings-label-value-container {
  display: flex;
  justify-content: space-between;
}

.settings-label {
  color: $gray-dark;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  text-transform: uppercase;
}

.settings-value {
  color: $gray-dark;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  text-transform: uppercase;
}

.patch-location-inactive,
.clinic-user-inactive {
  color: $gray-light;
}

.add-new-section {
  display: flex;
  flex-direction: column;
}
.settings-tab {
  padding-top: 36px;
  min-width: 800px;
}
.narrow-tab {
  max-width: 600px;
}
</style>
