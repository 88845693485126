import { GetterTree } from "vuex";
import { State } from "./state";
import { SiteConfig } from "@/shared/types/site-config";
import { User } from "@/shared/types/user";
import { Clinic } from "@/shared/types/clinic";

export type Getters = {
  site(state: State): string;
  siteConfig(state: State): SiteConfig;
  userId(state: State): number;
  user(state: State): User;
  clinicId(state: State): number;
  clinic(state: State): Clinic;
  lastRequest(state: State): Date;
  release(state: State): string;
  udi(state: State): string;
  environment(state: State): string;
  components(state: State): object;
  features(state: State): object;
};

export const getters: GetterTree<State, State> & Getters = {
  site: (state) => {
    return state.site;
  },
  siteConfig: (state) => {
    return state.siteConfig;
  },
  userId: (state) => {
    return state.userId;
  },
  user: (state) => {
    return state.user;
  },
  clinicId: (state) => {
    return state.clinicId;
  },
  clinic: (state) => {
    return state.clinic;
  },
  lastRequest: (state) => {
    return state.lastRequest;
  },
  release: (state) => {
    return state.release;
  },
  udi: (state) => {
    return state.udi;
  },
  environment: (state) => {
    return state.environment;
  },
  components: (state) => {
    return state.components;
  },
  features: (state) => {
    return state.features;
  },
};
