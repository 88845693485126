<template>
  <div>
    <div>
      <div id="login-main" class="container-narrow">
        <div id="login-logo-container" class="main-logo">
          <img id="login-logo" src="/alio_rms_logo.png" alt="main-logo" />
        </div>
        <div id="login-logging-in">
          <span>{{ $t("login.loggingIn") }}</span>
        </div>
      </div>
    </div>
    <privacy-policy-modal
      modal-id="privacy-policy-modal"
      :require-accept="privacy.requireAccept"
      :last-accepted-version="privacy.lastAcceptedVersion"
      @last-accepted-version="updateLastAcceptedVersion"
      @policy-accepted="acceptPrivacyPolicy"
      @policy-not-accepted="declinePrivacyPolicy"
    >
    </privacy-policy-modal>
  </div>
</template>
<script>
import { getCurrentUser } from "aws-amplify/auth";
import { Amplify } from "aws-amplify";

export default {
  data: function () {
    return {
      privacy: {
        requireAccept: true,
        lastAcceptedVersion: null,
      },
    };
  },
  mounted: function () {
    this.loadSiteInformation(this.$route.params.site);
    getCurrentUser()
      .then(() => {
        this.getUserInfo();
      })
      .catch(() => {
        this.$router.replace({ name: "login" });
      });
  },
  methods: {
    loadSiteInformation: function (site) {
      this.$store.commit("updateSite", site);
      this.$store.commit("updateSiteConfig", null);

      if (!site) {
        this.siteLoading = false;
        this.$bus.emit(
          "show-general-error",
          this.$t("login.errorNoSiteSpecified")
        );
        return;
      }

      const siteFiltered = site.replace(/[^A-Za-z0-9.-]+/g, "") + ".json";

      if (import.meta.env.VITE_CLINICIAN_SITE_OVERRIDE) {
        this.siteLoading = false;
        this.$store.commit(
          "updateSiteConfig",
          JSON.parse(import.meta.env.VITE_CLINICIAN_SITE_OVERRIDE)
        );
        // Configure Amplify with the config information
        Amplify.configure(this.getAwsConfig());
      } else {
        this.axios({
          method: "get",
          baseURL: "", // Local server
          url: "/sites/" + siteFiltered,
        })
          .then((response) => {
            this.siteLoading = false;
            this.$store.commit("updateSiteConfig", response.data);
            // Configure Amplify with the config information
            Amplify.configure(this.getAwsConfig());
          })
          .catch((error) => {
            this.siteLoading = false;
            console.log(error);
            this.$bus.emit(
              "show-general-error",
              this.$t("login.errorUnknownSite")
            );
          });
      }
    },
    acceptPrivacyPolicy: function (version) {
      // Accept Privacy Policy not currently support, so just shortcut it
      this.api
        .v1ClinicuserSelfAcceptprivacypolicyPut({
          acceptance: {
            privacypolicyacceptedversion: version,
          },
        })
        .then((response) => {
          this.completeLogin();
        })
        .catch((error) => {
          // Get and display the error message
          this.showApiResponseError(
            error,
            this.$t("privacyPolicy.errorAccept")
          );
          this.$router.replace({ name: "login" });
        });
    },
    saveLastLoginTime: function () {
      // Accept Privacy Policy not currently support, so just shortcut it
      this.api
        .v1ClinicuserSelfLoginPost()
        .then((response) => {
          // Do nothing on success
        })
        .catch((error) => {
          // Get and log any error message
          this.logApiResponseError(
            "Login Time",
            error,
            this.$t("login.errorLoginTracking")
          );
        });
    },
    declinePrivacyPolicy: function (version) {
      // Renable the buttons
      this.$router.replace({ name: "login" });
    },
    updateLastAcceptedVersion: function (version) {
      this.privacy.lastAcceptedVersion = version;
    },
    showPrivacyPolicyModal: function (requireAccept, lastAcceptedVersion) {
      this.privacy.requireAccept = requireAccept;
      if (
        lastAcceptedVersion === null ||
        this.privacy.lastAcceptedVersion !== lastAcceptedVersion
      ) {
        this.$bvModal.show("privacy-policy-modal");
      } else {
        this.completeLogin();
      }
    },
    completeLogin: function () {
      const clinicUser = this.$store.getters.user;

      if (
        !clinicUser ||
        clinicUser.clinics.length === 0 ||
        clinicUser.clinics[0] === null
      ) {
        // Renable the buttons
        this.buttonsEnabled = true;

        this.$bus.emit(
          "show-general-error",
          this.$t("login.errorNotAssociated")
        );
      } else {
        let clinicId = clinicUser.clinics[0];
        let next = this.$route.query.next;

        // Save the current clinic ID
        this.setCurrentClinicId(clinicId);

        if (next) {
          this.$router.replace({ path: next });
        } else {
          this.$router.replace({ name: "find-home" });
        }

        // Send an event for successful login
        this.$bus.emit("login-complete");
      }
    },
    getUserInfo: function () {
      this.api
        .v1WhoamiGet()
        .then((response) => {
          const userId = response.data.clinicuserid;
          this.setCurrentUserId(userId);
          this.saveLastLoginTime();
          const groups = response.data.groups;
          this.refreshCurrentUserInfoFromApi(
            (response) => {
              const user = response.data.clinicuser;

              if (!user) {
                this.$bus.emit(
                  "show-general-error",
                  this.$t("login.errorNoUser")
                );
                this.$router.replace({ name: "login" });
              } else {
                // Add the groups to the user
                user.groups = groups;
                this.$store.commit("updateUser", user);
                this.showPrivacyPolicyModal(
                  true,
                  user.privacypolicyacceptedversion
                );
              }
            },
            (error) => {
              // Get and show the error message
              this.showApiResponseError(
                error,
                this.$t("login.errorLoadDetails")
              );
              this.$router.replace({ name: "login" });
            }
          );
        })
        .catch((error) => {
          this.showApiResponseError(error, this.$t("login.errorLoadDetails"));
          this.$router.replace({ name: "login" });
        });
    },
  },
};
</script>
<style lang="scss">
#login-logo {
  width: 200px;
}

#login-title {
  color: $gray-mid;
  margin-top: 24px;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 26px;
  text-transform: uppercase;
}

#login-logging-in {
  margin-top: 40px;

  span {
    font-size: 18px;
  }
}
</style>
