import Vue from "vue";
import VueRouter from "vue-router";
import { getCurrentUser } from "aws-amplify/auth";
import store from "@/clinician/store";
import axios from "axios";

// Specific Pages
import Login from "./pages/Login.vue";
import LoginSSO from "./pages/LoginSSO.vue";
import Settings from "./pages/Settings.vue";

// Common Pages
import { sharedRoutes } from "@/shared/routes";
import { Amplify } from "aws-amplify";

import { getClinicianAmplifyConfig } from "@/shared/utils";

Vue.use(VueRouter);

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: "history",
  routes: [
    {
      // On base redirect go to patient-list, but if not currently
      // signed in, then go back to login
      path: "/",
      name: "find-home",
      redirect: () => {
        // Needs to use a function for redirect to ensure params
        // are set properly
        return {
          name: "patient-list",
          params: { clinicId: store.getters.clinicId },
        };
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      // The parameter :site is only optional to allow for an error
      // to be shown when it is not provided
      path: "/login/:site?",
      name: "login",
      component: Login,
      meta: {
        showNavigation: false,
      },
    },
    {
      // The parameter :site is only optional to allow for an error
      // to be shown when it is not provided
      path: "/login/:site?/sso",
      name: "login-sso",
      component: LoginSSO,
      meta: {
        showNavigation: false,
      },
    },
    {
      path: "/settings/:clinicId",
      name: "settings",
      component: Settings,
      meta: {
        requiresAuth: true,
        showNavigation: true,
      },
    },
    ...sharedRoutes,
    {
      path: "*",
      redirect: {
        name: "find-home",
      },
    },
  ],
});

// Authentication check, if not authorized, go back to login
router.beforeResolve(async (to, from, next) => {
  if (!to.matched.some((record) => record.meta.requiresAuth)) return next();

  let user = null;
  try {
    user = await getCurrentUser();
    // this means we were already configured
    if (user) return next();
  } catch (err) {
    if (
      err instanceof Error &&
      err.message.includes("Auth is not configured")
    ) {
      // If there is an awsConfig, then use it configure Amplify
      const awsConfig = getClinicianAmplifyConfig(store);
      if (awsConfig) {
        Amplify.configure(awsConfig);
      }
    }
  }

  try {
    user = await getCurrentUser();
    if (user) return next();
  } catch (err) {
    // If the 'to' was not already a redirect then use it as a next. If
    // it was redirected then we just ignore it as it means it was likely
    // going somewhere that does not exist
    let nextUrl = "redirectedFrom" in to ? null : to.path;
    let site = store.getters.site;

    if (site) {
      next({
        name: "login",
        params: { site: site },
        query: nextUrl !== null ? { next: nextUrl } : null,
      });
    } else {
      next({
        name: "login",
        query: nextUrl !== null ? { next: nextUrl } : null,
      });
    }
  }
});

// Add in support for cancelling existing axios commands on any navigation. In
// general all commands for a particular page are expected to be complete before
// going through any navigation.
let cancelSource = axios.CancelToken.source();

axios.interceptors.request.use(async (config) => {
  config.cancelToken = cancelSource.token;
  return config;
});

router.beforeEach((to, from, next) => {
  // Cancel any current requests.
  cancelSource.cancel("Operation canceled by navigation.");
  cancelSource = axios.CancelToken.source();

  // Continue on with the navigation
  next();
});

export default router;
